






































































































































































































































































































































import moment from 'moment'
import {Component, Ref} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {
    Coupon,
    CourseDiscountCouponData,
    CourseRewardData,
    CurrencyRewardData,
    DiscountCouponType,
    Instructor,
    RewardType,
    SplitType,
} from '~/components/data-class/data-class'
import InstructorsData from '~/components/instructor/instructors-data'
import {AdminPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import PaginationMixin from '~/mixins/pagination-mixin'
import SeriesSelect from '~/components/autocomplete/series-select.vue'
import CouponAmount from '~/components/coupon/coupon-amount.vue'
import CouponReward from '~/components/coupon/coupon-reward.vue'
import CouponPreview from '~/components/coupon/coupon-preview.vue'

@Component({
    components: {
        SeriesSelect,
        CouponAmount,
        CouponReward,
        CouponPreview
    },
    metaInfo() {
        return {
            title: '優惠券'
        }
    }
})
export default class CouponCodeManager extends mixins(PaginationMixin) {
    static permission = AdminPermission.Coupon

    coupons: Coupon[] = []

    // query
    qTypes: RewardType[] = []
    hideExpired: boolean = true
    hideSystem: boolean = true
    keyword: string = ''

    // UI
    cLoading: boolean = false
    showDetails: boolean = false
    codeErr: string = ''
    missingTitle: boolean = false
    missingData: boolean = false

    newCoupon: boolean = false
    currentCoupon: Coupon = new Coupon

    instructors: Instructor[] = []

    step = 20

    @Ref() tableWrapperShadowElement: HTMLElement
    @Ref() tableWrapperElement: HTMLElement
    tableScrollable: boolean = false
    tableShadowLeft: boolean = false

    beforeEdit: string = ''

    amountToPercentOff(amount: number) {
        return 100 - amount
    }

    percentOffToAmount(pf: number) {
        return 100 - pf
    }

    flatDefault = () => {
        return {
            min_spend: 0,
            amount: 1,
            type: DiscountCouponType.AfterSchool,
            expire: 0,
            split_type: SplitType.AfterSchool,
            split_value: -1,
            scope: '',
            reusable: false
        }
    }

    ratioDefault = () => {
        return {
            min_spend: 0,
            amount: 99,
            type: DiscountCouponType.AfterSchool,
            expire: 0,
            split_type: SplitType.AfterSchool,
            split_value: -1,
            scope: '',
            reusable: false
        }
    }

    flatCouponData: CourseDiscountCouponData = this.flatDefault()
    ratioCouponData: CourseDiscountCouponData = this.ratioDefault()

    get ratioPercentOff() {
        return this.amountToPercentOff(this.ratioCouponData.amount)
    }

    set ratioPercentOff(pf) {
        this.ratioCouponData.amount = this.percentOffToAmount(pf)
    }

    get currentExpire(): Date | null {
        const n = this.currentCoupon.expire
        return (n) ? new Date(n) : null
    }

    set currentExpire(v: Date | null) {
        this.$set(this.currentCoupon, 'expire', v ? v.getTime() : 0)
    }

    get flatCouponExpire(): Date | null {
        const n = this.flatCouponData.expire
        return (n) ? new Date(n) : null
    }

    get ratioCouponExpire(): Date | null {
        const n = this.ratioCouponData.expire
        return (n) ? new Date(n) : null
    }

    get currentCouponData() {
        return (this.currentCoupon.reward.type === RewardType.COURSE_DISCOUNT_RATIO) ?
            this.ratioCouponData : this.flatCouponData
    }

    get splitValue() {
        if (this.currentCouponData.split_value >= 0)
            return this.currentCouponData.split_value * 100
        return ''
    }

    set splitValue(raw) {
        if (raw === '')
            this.$set(this.currentCouponData, 'split_value', -1)
        else
            this.$set(this.currentCouponData, 'split_value', raw / 100)
    }

    get currentCouponExpire() {
        return (this.currentCoupon.reward.type === RewardType.COURSE_DISCOUNT_RATIO) ?
            this.ratioCouponExpire : this.flatCouponExpire
    }

    set currentCouponExpire(v: Date | null) {
        const d = (this.currentCoupon.reward.type === RewardType.COURSE_DISCOUNT_RATIO) ?
            this.ratioCouponData : this.flatCouponData
        this.$set(d, 'expire', v ? v.getTime() : 0)
    }

    DiscountCouponType = DiscountCouponType

    coinsData: CurrencyRewardData = {amount: 1}
    diamondsData: CurrencyRewardData = {amount: 1}
    courseData: CourseRewardData = {course_id: '', note: false}

    coinInput() {
        this.coinsData.amount = Math.max(1, this.coinsData.amount)
    }

    diamondInput() {
        this.diamondsData.amount = Math.max(1, this.diamondsData.amount)
    }

    RewardType = RewardType
    types: { label: string, id: number }[] = [
        {label: '金幣', id: RewardType.COIN},
        {label: '鑽石', id: RewardType.DIAMOND},
        {label: '折扣金額', id: RewardType.COURSE_DISCOUNT_FLAT},
        {label: '折扣百分比', id: RewardType.COURSE_DISCOUNT_RATIO},
        {label: '課程', id: RewardType.COURSE}
    ]

    typesCell = {
        [RewardType.COIN]: '金幣',
        [RewardType.DIAMOND]: '鑽石',
        [RewardType.COURSE_DISCOUNT_FLAT]: '折扣',
        [RewardType.COURSE_DISCOUNT_RATIO]: '折扣%',
        [RewardType.COURSE]: '課程',
    }

    couponTypes: { label: string, id: number }[] = [
        {label: '訂單總額折扣', id: DiscountCouponType.AfterSchool},
        {label: '指定導師折扣', id: DiscountCouponType.Instructor},
        {label: '指定課程折扣', id: DiscountCouponType.Course},
        {label: '指定課程系列', id: DiscountCouponType.Series},
        {label: '導師推薦試堂', id: DiscountCouponType.Trial},
        {label: '導師專屬折扣', id: DiscountCouponType.InstructorReferral},
    ]

    propSelectText = {
        [DiscountCouponType.Instructor]: '導師',
        [DiscountCouponType.Course]: '課程',
        [DiscountCouponType.Series]: '課程系列',
        [DiscountCouponType.Trial]: '邀請方導師',
        [DiscountCouponType.InstructorReferral]: '邀請方導師',
    }

    couponTypeChanged() {
        const t = this.currentCouponData.type
        if (t !== DiscountCouponType.Course) {
            delete this.currentCouponData.course_id
        }
        if (![DiscountCouponType.Instructor, DiscountCouponType.InstructorReferral, DiscountCouponType.Trial].includes(t)) {
            delete this.currentCouponData.instructor
        }
        if (t !== DiscountCouponType.Series) {
            delete this.currentCouponData.series_id
        }
        if (t === DiscountCouponType.InstructorReferral) {
            this.currentCouponData.split_type = SplitType.Instructor
            this.currentCouponData.expire = 0
        }
        if (t === DiscountCouponType.AfterSchool) {
            this.currentCouponData.split_value = -1
        }
        if (t === DiscountCouponType.Trial) {
            this.currentCouponData.expire = 0
        }

        if (![DiscountCouponType.AfterSchool, DiscountCouponType.Instructor, DiscountCouponType.Series].includes(t)) {
            delete this.currentCouponData.tiers
        }

        this.missingData = false
    }

    dateToExpire(date: Date | null) {
        if (date == null) return 0
        date.setHours(23, 59, 59, 999)
        return date.getTime()
    }

    updateExpire(date: Date | null) {
        this.currentCoupon.expire = this.dateToExpire(date)
    }

    updateCouponExpire(date: Date | null) {
        this.currentCouponData.expire = this.dateToExpire(date)
    }

    async created() {
        await InstructorsData.init()
        this.instructors = InstructorsData.instructors
        await this.getCoupons()
    }

    getInstructor(mid) {
        return InstructorsData.getInstructor(mid)
    }

    async toPage(page: number = 1) {
        this.currentPage = page
        await this.getCoupons()
    }

    async getCoupons() {
        const q = {
            exclude_expired: this.hideExpired.toString(),
            system: !this.hideSystem,
            limit: this.step,
            skip: this.skip,
            keyword: this.keyword
        }

        if (this.qTypes.length) {
            q['types'] = this.qTypes.join(',')
        }

        this.cLoading = true
        const res = await createRequest('/coupons', 'get', q).send()
        this.coupons = res.data.coupons
        this.totalCount = res.data.count
        this.cLoading = false
    }

    async startEdit(coupon) {
        this.newCoupon = false

        this.beforeEdit = JSON.stringify(coupon)
        this.currentCoupon = JSON.parse(this.beforeEdit)

        switch (this.currentCoupon.reward.type) {
            case RewardType.COIN:
                this.coinsData = this.currentCoupon.reward.data as CurrencyRewardData
                break
            case RewardType.DIAMOND:
                this.diamondsData = this.currentCoupon.reward.data as CurrencyRewardData
                break
            case RewardType.COURSE:
                this.courseData = this.currentCoupon.reward.data as CourseRewardData
                break
            case RewardType.COURSE_DISCOUNT_FLAT:
                this.flatCouponData = this.currentCoupon.reward.data as CourseDiscountCouponData
                break
            case RewardType.COURSE_DISCOUNT_RATIO:
                this.ratioCouponData = this.currentCoupon.reward.data as CourseDiscountCouponData
                break
        }

        this.missingTitle = false
        this.codeErr = ''
        this.showDetails = true
    }

    async createNewCoupon() {
        this.newCoupon = true
        this.currentCoupon = new Coupon()

        this.coinsData = {amount: 1}
        this.diamondsData = {amount: 1}
        this.courseData = {course_id: '', note: false}
        this.flatCouponData = this.flatDefault()
        this.ratioCouponData = this.ratioDefault()
        await this.randomCode()
        this.missingTitle = false
        this.codeErr = ''
        this.showDetails = true
    }

    async codeChanged(code) {
        if (!code)
            return

        const res = await createRequest('/coupon/code/used', 'get', {code}).send()
        if (res.data.used)
            this.codeErr = '此兌換碼已被使用'
        else
            this.codeErr = ''
    }

    titleChanged() {
        this.missingTitle = false
    }

    async submitCoupon() {
        const c = this.currentCoupon

        if (!c.title)
            this.missingTitle = true

        this.missingData = false

        if([RewardType.COURSE_DISCOUNT_RATIO, RewardType.COURSE_DISCOUNT_FLAT].includes(c.reward.type)) {
            switch (this.currentCouponData.type) {
                case DiscountCouponType.Course:
                    this.missingData = !this.currentCouponData.course_id
                    break
                case DiscountCouponType.Series:
                    this.missingData = !this.currentCouponData.series_id
                    break
                case DiscountCouponType.Instructor:
                case DiscountCouponType.InstructorReferral:
                case DiscountCouponType.Trial:
                    this.missingData = !this.currentCouponData.instructor
                    break
            }
        }
        if(c.reward.type === RewardType.COURSE)
            this.missingData = !this.courseData.course_id

        if (this.missingTitle || this.codeErr || this.missingData)
            return

        switch (c.reward.type) {
            case RewardType.COIN:
                c.reward.data = this.coinsData
                break
            case RewardType.DIAMOND:
                c.reward.data = this.diamondsData
                break
            case RewardType.COURSE:
                c.reward.data = this.courseData
                break
            case RewardType.COURSE_DISCOUNT_FLAT:
                c.reward.data = this.flatCouponData
                break
            case RewardType.COURSE_DISCOUNT_RATIO:
                c.reward.data = this.ratioCouponData
                break
        }

        const body = {
            expire: c.expire,
            title: c.title,
            code: c.code,
            // phone_numbers: [],
            reward: c.reward
        }
        if (this.newCoupon) {
            await createRequest('/coupon', 'post', {}, body).send()
        } else {
            await createRequest(`/coupon/${c._id}`, 'patch', {}, body).send()
        }
        await this.getCoupons()
        this.currentCoupon = new Coupon()
        this.$message({
            message: 'Saved!',
            type: 'success'
        })
        this.showDetails = false
    }

    async randomCode() {
        const res = await createRequest('/coupon/code/generate', 'post').send()
        this.currentCoupon.code = res.data.code
        this.codeErr = ''
    }

    time(timestamp?: number) {
        return timestamp ? moment(timestamp).format('DD/MM/YYYY') : ''
    }

    tableScrolled() {
        if (this.tableWrapperElement.scrollLeft === 0) {
            this.tableShadowLeft = false
        } else if (this.tableWrapperElement.offsetWidth + this.tableWrapperElement.scrollLeft === this.tableWrapperElement.scrollWidth) {
            this.tableShadowLeft = true
        }
    }

    deleteCoupon(coupon: Coupon) {
        this.$confirm('此操作將不能復原，被刪除的優惠券將即時失效，你確定要刪除此優惠券？', {
            title: '刪除優惠券',
            confirmButtonText: '確定刪除',
            cancelButtonText: '不要刪除',
            confirmButtonClass: 'btn-danger',
            lockScroll: false
        }).then(async () => {
            await createRequest(`/coupon/${coupon._id}`, 'delete').send()
            await this.getCoupons()
        }).catch(() => {
        })
    }

    closeEditor(done) {
        if (this.beforeEdit !== JSON.stringify(this.currentCoupon)) {
            this.$confirm('你還未儲存你輸入的資料或進行的修改，你確定放棄儲存？', {
                title: '放棄儲存',
                confirmButtonText: '放棄儲存',
                cancelButtonText: '取消',
                confirmButtonClass: 'btn-danger',
                lockScroll: false
            }).then(async () => {
                done()
            }).catch(() => {
            })
        } else {
            done()
        }
    }

    mounted() {
        new ResizeObserver((entries) => {
            const el = entries[0].target
            this.tableScrollable = el.scrollWidth > el.clientWidth
        }).observe(this.tableWrapperElement)
    }
}

