
























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {
    Coupon,
    CouponTier,
    CourseDiscountCouponData,
    DiscountCouponType,
    RewardType
} from '../data-class/data-class'

@Component({
    components: {}
})
export default class CouponAmount extends Vue {
    @Prop()
    currentCoupon: Coupon
    @Prop()
    currentCouponData: CourseDiscountCouponData

    tiers: CouponTier[] = [{amount: 0, min_spend: 0}]

    get allowTiers() {
        return [DiscountCouponType.AfterSchool, DiscountCouponType.Instructor, DiscountCouponType.Series].includes(this.currentCouponData.type)
    }

    get ratio() {
        return this.currentCoupon.reward.type === RewardType.COURSE_DISCOUNT_RATIO
    }

    addTier() {
        const last = this.tiers.last()
        const next = {
            amount: last.amount + 1,
            min_spend: last.min_spend + 1
        }
        this.tiers.push(next)
        this.tiersChanged()
    }

    removeTier(i: number) {
        this.tiers.splice(i, 1)
        if (this.tiers.length <= 1) {
            delete this.currentCouponData.tiers
            this.$emit('amountUpdated', this.currentCouponData)
        } else {
            this.tiersChanged()
        }
    }

    amountToPercentOff(amount: number) {
        return 100 - amount
    }

    percentOffToAmount(pf: number) {
        return 100 - pf
    }

    tiersChanged() {
        this.currentCouponData.amount = this.ratio ? this.percentOffToAmount(this.tiers[0].amount) : this.tiers[0].amount
        this.currentCouponData.min_spend = this.tiers[0].min_spend
        if (this.tiers.length === 1) {
            delete this.currentCouponData.tiers
        } else {
            const tiers = this.tiers.map((tier) => {
                return {
                    amount: this.ratio ? this.percentOffToAmount(tier.amount) : tier.amount,
                    min_spend: tier.min_spend
                }
            })
            this.$set(this.currentCouponData, 'tiers', tiers)
        }
        this.$emit('amountUpdated', this.currentCouponData)
    }

    ensureAmount() {
        const old = JSON.stringify(this.tiers)
        for (const [i, tier] of this.tiers.entries()) {
            if (i === 0) {
                tier.min_spend = Math.max(0, tier.min_spend)
                tier.amount = Math.max(1, tier.amount)
                if(this.ratio)
                    tier.amount = Math.min(100, tier.amount)
                continue
            }

            tier.amount = Math.max(tier.amount, this.tiers[i - 1].amount + 1)
            tier.min_spend = Math.max(tier.min_spend, this.tiers[i - 1].min_spend + 1)
        }
        if(old !== JSON.stringify(this.tiers))
            this.tiersChanged()
    }

    @Watch('currentCouponData', {deep: true})
    discountChanged(ccd: CourseDiscountCouponData) {
        if (!ccd.tiers) {
            this.tiers = [{
                amount: this.ratio ? this.amountToPercentOff(ccd.amount) : ccd.amount,
                min_spend: ccd.min_spend
            }]
        } else {
            this.tiers = ccd.tiers.map((tier) => {
                return {
                    amount: this.ratio ? this.amountToPercentOff(tier.amount) : tier.amount,
                    min_spend: tier.min_spend
                }
            })
        }
    }

    created() {
        this.discountChanged(this.currentCouponData)
    }
}
