



































import {Coupon, CourseDiscountCouponData, RewardType} from '~/components/data-class/data-class'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import moment from 'moment'

@Component({})
export default class CouponPreview extends Vue {

    @Prop() coupon: Coupon
    @Prop() discountData: CourseDiscountCouponData

    amountTxt = ''

    //  META DATA
    rewardType = RewardType

    created() {
        this.$on('amountUpdated', this.updateAmount)
        this.updateAmount()
    }

    @Watch('discountData', {deep: true})
    updateAmount(cd: CourseDiscountCouponData = this.discountData) {
        const isRatio = this.coupon.reward.type === RewardType.COURSE_DISCOUNT_RATIO
        if (cd.tiers && cd.tiers.length > 1) {
            const f = cd.tiers[0]
            const l = cd.tiers.last()
            this.amountTxt = isRatio ? `${100 - f.amount}~${100 - l.amount}% off` : `$${f.amount}~${l.amount}`
        } else {
            this.amountTxt = isRatio ? `${100 - cd.amount}% off` : `$${cd.amount}`
        }
    }

    get expireDate() {
        return moment(this.discountData.expire).format('DD/MM/YYYY')
    }
}
