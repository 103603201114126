





































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Coupon, CourseDiscountCouponData, DiscountCouponType, RewardType} from '../data-class/data-class'
import InstructorsData from '~/components/instructor/instructors-data'
import SeriesData from '~/components/series/series-data'

@Component({
    components: {}
})
export default class CouponAmount extends Vue {
    @Prop()
    coupon: Coupon

    RewardType = RewardType
    DiscountCouponType = DiscountCouponType

    instructorName = ''
    seriesTitle = ''

    get reward() {
        return this.coupon.reward.data
    }

    get discountData() {
        return this.coupon.reward.data as CourseDiscountCouponData
    }

    get type() {
        return this.coupon.reward.type
    }

    get discountType() {
        return this.discountData.type
    }

    get instructorCoupon() {
        return [
            DiscountCouponType.Instructor,
            DiscountCouponType.Trial,
            DiscountCouponType.InstructorStudentReferral,
            DiscountCouponType.InstructorReferral
        ].includes(this.discountType)
    }

    async mounted() {
        await this.checkSeriesTutorTitle()
    }

    @Watch('discountType')
    @Watch('discountData.instructor')
    @Watch('discountData.series_id')
    async checkSeriesTutorTitle() {
        if (this.instructorCoupon) {
            const i = await InstructorsData.shouldGetInstructor(this.discountData.instructor!)
            this.instructorName = i!.display_name!
        }
        if (this.discountType === DiscountCouponType.Series) {
            const s = await SeriesData.shouldGetSeries(this.discountData.series_id!)
            this.seriesTitle = s!.title!
        }
    }

    get isDiscount() {
        return [RewardType.COURSE_DISCOUNT_FLAT, RewardType.COURSE_DISCOUNT_RATIO].includes(this.type)
    }

    get discountAmount() {
        const cd = this.discountData
        const isRatio = this.type === RewardType.COURSE_DISCOUNT_RATIO
        if (cd.tiers && cd.tiers.length > 1) {
            const f = cd.tiers[0]
            const l = cd.tiers.last()
            return isRatio ? `${100 - f.amount} ~ ${100 - l.amount}%` : `$${f.amount} ~ ${l.amount}`
        } else {
            return isRatio ? `${100 - cd.amount}%` : `$${cd.amount}`
        }
    }
}
